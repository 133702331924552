import { useTranslation } from 'modules/common/hooks/useTranslation'
import { useCtaModal } from 'modules/common/modals/useCtaModal/useCtaModal'
import { UserFeedbackSuccessModalContent } from '../components/UserFeedbackSuccessModalContent/UserFeedbackSuccessModalContent'

export const useUserFeedbackSuccessModal = () => {
  const { i18n, t } = useTranslation('common')

  return useCtaModal({
    content: <UserFeedbackSuccessModalContent />,
    i18n,
    primaryButtonText: t('understood'),
    overlayTransitionType: 'transition-none',
    width: 'base',
  })
}
