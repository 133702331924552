import { mergeClasses } from '@blank/utilities'
import { motion } from 'framer-motion'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { EnumSubMenuStatus, SubMenu } from 'modules/common/types/sidebar'
import { Link } from '../../Link/Link'
import {
  subMenuVariants,
  useSubMenusAnimation,
} from '../hooks/useSubMenusAnimation'

interface Props {
  isFullyOpen: boolean
  subMenus: SubMenu[]
  selectedSubMenuId?: string
}

const SubMenus = ({ isFullyOpen, subMenus, selectedSubMenuId }: Props) => {
  const { t } = useTranslation(['common-components'])

  const controls = useSubMenusAnimation()

  return (
    <ul>
      {subMenus.map((subMenu) => (
        <motion.li
          key={subMenu.id}
          animate={controls}
          style={isFullyOpen ? undefined : { x: subMenuVariants.closed.x }}
          className="h-fit-content w-fit-content flex items-center text-base"
        >
          <Link
            href={subMenu.link}
            className={mergeClasses(
              'flex h-full w-full items-center p-5 font-bold',
              {
                'text-info hover:text-info-800':
                  selectedSubMenuId !== subMenu.id,
                'pointer-events-none':
                  subMenu.status !== EnumSubMenuStatus.ACTIVE,
              }
            )}
            data-testid={`sub-menu-${subMenu.id}`}
          >
            {t(`sidebar.subMenuLabels.${subMenu.id}`)}
          </Link>
        </motion.li>
      ))}
    </ul>
  )
}

export default SubMenus
