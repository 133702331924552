import { useSidebarActionsContext } from 'modules/common/contexts/SidebarContext/SidebarActionsContext'
import {
  EnumSidebarMenu,
  EnumSidebarSubMenu,
} from 'modules/common/types/sidebar'
import { useEffect } from 'react'

interface Props {
  menuId: EnumSidebarMenu
  subMenuId?: EnumSidebarSubMenu
}
export const useSelectSidebarMenu = ({ menuId, subMenuId }: Props) => {
  const { selectSubMenu, selectMenu, selectFirstSubMenu } =
    useSidebarActionsContext()

  useEffect(() => {
    selectMenu(menuId)

    // If submenu is specified, on open it.
    // Else, open the first submenu for the select menu, if any.
    if (subMenuId) {
      selectSubMenu(subMenuId)
    } else {
      selectFirstSubMenu(menuId)
    }
  }, [menuId, selectMenu, selectSubMenu, selectFirstSubMenu, subMenuId])
}
