import { IconInvoice } from 'modules/common/components/_icons/icons'
import { EnumRoutes } from 'modules/common/routes'
import {
  EnumSidebarMenu,
  EnumSidebarSubMenu,
  EnumSubMenuStatus,
} from 'modules/common/types/sidebar'
import { useMemo } from 'react'
import { useTranslation } from '../useTranslation'

export const useInvoicingPageSidebarConfig = () => {
  const { t } = useTranslation('common-components')

  return useMemo(
    () => ({
      id: EnumSidebarMenu.INVOICING,
      icon: <IconInvoice />,
      link: EnumRoutes.INVOICING_DOCUMENTS,
      title: t(`sidebar.menuLabels.${EnumSidebarMenu.INVOICING}`),
      subMenus: [
        {
          id: EnumSidebarSubMenu.INVOICING_HOME,
          link: EnumRoutes.INVOICING_DOCUMENTS,
          status: EnumSubMenuStatus.ACTIVE,
        },
        {
          id: EnumSidebarSubMenu.INVOICING_CUSTOMERS,
          link: EnumRoutes.INVOICING_CUSTOMERS,
          status: EnumSubMenuStatus.ACTIVE,
        },
        {
          id: EnumSidebarSubMenu.INVOICING_SETTINGS,
          link: EnumRoutes.INVOICING_SETTINGS,
          status: EnumSubMenuStatus.ACTIVE,
        },
      ],
    }),
    [t]
  )
}
