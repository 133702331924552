import LayoutSimple from 'modules/common/components/_layouts/LayoutSimple'
import { Button } from 'modules/common/components/_ui/Button/Button'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { Trans } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import { Illustration } from '../Illustrations/Illustration'
import { Link } from '../Link/Link'

const LayoutMobileProtected = () => {
  const { t } = useTranslation('common')
  const [url, setUrl] = useState('')

  useEffect(() => {
    setUrl(window.location.origin)
  }, [])

  return (
    <LayoutSimple>
      <div className="box-content flex w-full max-w-xs flex-col items-center justify-center space-y-8 px-6 py-10">
        <Illustration name="update" size="xl" />
        <div className="text-center text-info-600">
          <Trans parent="h2" className="my-5 text-center">
            {t('mobile.title')}
          </Trans>
          <Trans parent="p">{t('mobile.text')}</Trans>
        </div>
        <Link href={`${url}/telechargement/app-mobile`} className="w-full">
          <Button variant="primary" color="default" className="w-full">
            {t('mobile.button')}
          </Button>
        </Link>
      </div>
    </LayoutSimple>
  )
}

export default LayoutMobileProtected
