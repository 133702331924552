import React from 'react'
import { motion, Variants } from 'framer-motion'
import { IconMenuCloseLeft, IconMenu } from '../../_icons/icons'
import { useSidebarActionsContext } from 'modules/common/contexts/SidebarContext/SidebarActionsContext'

const buttonVariants: Variants = {
  closed: {
    x: 0,
  },
  opened: {
    x: '12rem',
  },
}

interface Props {
  isFullyOpen: boolean
}

const MenuToggle = ({ isFullyOpen }: Props) => {
  const { toggleSidebar } = useSidebarActionsContext()

  return (
    <motion.button
      variants={buttonVariants}
      transition={{ ease: 'backIn' }}
      onClick={toggleSidebar}
      className="h-8 w-8 text-info-600"
    >
      {isFullyOpen ? <IconMenuCloseLeft /> : <IconMenu />}
    </motion.button>
  )
}

export default MenuToggle
