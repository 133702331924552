import { useSidebarActionsContext } from 'modules/common/contexts/SidebarContext/SidebarActionsContext'
import { useCountryExternalLinks } from 'modules/common/hooks/useCountryExternalLinks'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import useSupportModal from 'modules/common/modals/useSupportModal/useSupportModal'
import { EnumRoutes } from 'modules/common/routes'
import { EnumSidebarMenu, Menu } from 'modules/common/types/sidebar'
import { useCanAccessSettingsPage } from 'modules/settings/hooks/useCanAccessSettingsPage'
import { Link } from '../../Link/Link'
import {
  IconConsultFaqs,
  IconSettings,
  IconShieldedDocument,
} from '../../_icons/icons'
import { Button } from '../../_ui/Button/Button'
import { PictoBarIcon } from './PictoBarIcon'

interface Props {
  menus: Menu[]
  isSidebarOpen: boolean
  selectedMenuId?: string
}

const PictoBar = ({ menus, isSidebarOpen, selectedMenuId }: Props) => {
  const { openSidebar } = useSidebarActionsContext()
  const externalLinks = useCountryExternalLinks()
  const { canAccessSettingsPage } = useCanAccessSettingsPage()

  const { showModal: showSupportModal } = useSupportModal()

  const { t } = useTranslation('common-components')

  const toggleMenu = async () => {
    if (!isSidebarOpen) {
      openSidebar()
    }
  }

  return (
    <div className="flex flex-col justify-between bg-primary-600 text-info-400">
      <div className="flex flex-col">
        {menus
          .filter((menu) => menu.id !== EnumSidebarMenu.SETTINGS)
          .map((menu) => (
            <Link href={menu.link} key={menu.id} title={menu.title}>
              <Button className="flex" onClick={toggleMenu} variant="none">
                <PictoBarIcon
                  icon={menu.icon}
                  id={menu.id}
                  isSelected={selectedMenuId === menu.id}
                  title={menu.title}
                />
              </Button>
            </Link>
          ))}
      </div>
      <div className="flex flex-col">
        <Button variant="none" onClick={showSupportModal}>
          <PictoBarIcon
            icon={<IconConsultFaqs />}
            id="HELP_CENTER"
            title={t('sidebar.menuLabels.HELP_CENTER')}
          />
        </Button>
        <Link href={externalLinks.legalLink} className="flex">
          <PictoBarIcon
            icon={<IconShieldedDocument />}
            id="LEGAL_LINKS"
            title={t('sidebar.menuLabels.LEGAL_LINKS')}
          />
        </Link>
        {canAccessSettingsPage && (
          <Link href={EnumRoutes.SETTINGS_SUBSCRIPTION_HOME} className="flex">
            <PictoBarIcon
              icon={<IconSettings />}
              id="SETTINGS"
              title={t('sidebar.menuLabels.SETTINGS')}
              isSelected={selectedMenuId === EnumSidebarMenu.SETTINGS}
            />
          </Link>
        )}
      </div>
    </div>
  )
}

export default PictoBar
