import { Illustration } from 'modules/common/components/Illustrations/Illustration'
import { useTranslation } from 'modules/common/hooks/useTranslation'

export const UserFeedbackSuccessModalContent = () => {
  const { t } = useTranslation('common-components')

  return (
    <div className="flex flex-col items-center gap-4">
      <Illustration name="check" size="l" />
      <h2>{t('userFeedbackSuccessModal.title')}</h2>
      <p>{t('userFeedbackSuccessModal.text')}</p>
    </div>
  )
}
