import { BillingServiceApi } from '@blank/api'
import { IconServices } from 'modules/common/components/_icons/icons'
import { EnumRoutes } from 'modules/common/routes'
import { EnumSidebarMenu } from 'modules/common/types/sidebar'
import { useMemo } from 'react'
import { useTranslation } from '../useTranslation'

export const useServicesPageSidebarConfig = (
  services: BillingServiceApi[] | undefined
) => {
  const { t } = useTranslation('common-components')

  return useMemo(
    () => ({
      id: EnumSidebarMenu.SERVICES,
      icon: <IconServices />,
      link: EnumRoutes.SERVICES,
      title: t(`sidebar.menuLabels.${EnumSidebarMenu.SERVICES}`, {
        count: services?.length,
      }),
    }),
    [services?.length, t]
  )
}
