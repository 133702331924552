import { EnumOfferType } from '@blank/api'
import { useAuthenticatedUserCorporateQuery } from 'modules/auth/queries/useAuthenticatedUserCorporateQuery'
import { useCorporateOfferQuery } from 'modules/corporate/queries/useCorporateOfferQuery'

export const useCanAccessSettingsPage = () => {
  const {
    data: { corporate },
  } = useAuthenticatedUserCorporateQuery()

  const { data: offer } = useCorporateOfferQuery(corporate?.id)

  return {
    canAccessSettingsPage:
      (corporate?.subscriptions || []).length > 0 &&
      offer?.offerType !== EnumOfferType.MANAGEMENT_TOOLS,
  }
}
