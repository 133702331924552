import { EnumBillingServiceId } from '@blank/api'
import { useInternalAccount } from 'modules/bank/hooks/account/useInternalAccount'
import { IconBank } from 'modules/common/components/_icons/icons'
import { EnumRoutes } from 'modules/common/routes'
import {
  EnumSidebarMenu,
  EnumSidebarSubMenu,
  EnumSubMenuStatus,
} from 'modules/common/types/sidebar'
import { useCorporateLifecycleQueries } from 'modules/corporate/hooks/useCorporateLifecycleQueries'
import { useCorporateOfferServices } from 'modules/corporate/hooks/useCorporateOfferServices'
import { useMemo } from 'react'
import { useCountryConfig } from '../useCountryConfig'
import { useTranslation } from '../useTranslation'

export const useAccountPageSidebarConfig = () => {
  const { t } = useTranslation('common-components')

  const { data: internalAccount } = useInternalAccount()

  const {
    data: { isKycComplete },
    isSuccess: isKycLifecycleFetchSuccess,
  } = useCorporateLifecycleQueries()

  const { shouldDisplayAverageBalanceDocuments, canDepositCheck } =
    useCountryConfig()

  const { services } = useCorporateOfferServices()

  const hasCheckFeature = services?.some(
    (service) => service.id === EnumBillingServiceId.CHECK
  )

  const shouldDisplayChecksDeposit = hasCheckFeature || canDepositCheck

  const isKycPending = isKycLifecycleFetchSuccess && !isKycComplete

  const kycRestrictedSubMenuStatus = isKycPending
    ? EnumSubMenuStatus.PENDING
    : EnumSubMenuStatus.ACTIVE

  return useMemo(
    () => ({
      id: EnumSidebarMenu.ACCOUNT,
      icon: <IconBank />,
      link: EnumRoutes.ACCOUNTS,
      title: t(`sidebar.menuLabels.${EnumSidebarMenu.ACCOUNT}`),
      subMenus: [
        {
          id: EnumSidebarSubMenu.ACCOUNT_HOME,
          link: EnumRoutes.ACCOUNTS,
          status: EnumSubMenuStatus.ACTIVE,
        },
        ...(internalAccount && !internalAccount.isBlockedInvest
          ? [
              {
                id: EnumSidebarSubMenu.ACCOUNT_TRANSFER_ORDERS,
                link: EnumRoutes.ACCOUNT_TRANSFER_ORDERS,
                status: kycRestrictedSubMenuStatus,
              },
              {
                id: EnumSidebarSubMenu.ACCOUNT_BENEFICIARIES,
                link: EnumRoutes.ACCOUNT_BENEFICIARIES,
                status: kycRestrictedSubMenuStatus,
              },
              {
                id: EnumSidebarSubMenu.ACCOUNT_CARD,
                link: EnumRoutes.ACCOUNT_CARD,
                status: kycRestrictedSubMenuStatus,
              },
              {
                id: EnumSidebarSubMenu.ACCOUNT_MANDATES,
                link: EnumRoutes.ACCOUNT_MANDATES,
                status: kycRestrictedSubMenuStatus,
              },
              ...(shouldDisplayChecksDeposit
                ? [
                    {
                      id: EnumSidebarSubMenu.ACCOUNT_CHECK,
                      link: EnumRoutes.ACCOUNT_CHECK,
                      status: kycRestrictedSubMenuStatus,
                    },
                  ]
                : []),
              ...(shouldDisplayAverageBalanceDocuments
                ? [
                    {
                      id: EnumSidebarSubMenu.ACCOUNT_AVERAGE_BALANCE_DOCUMENTS,
                      link: EnumRoutes.ACCOUNT_AVERAGE_BALANCE_DOCUMENTS,
                      status: kycRestrictedSubMenuStatus,
                    },
                  ]
                : []),
            ]
          : []),
      ],
    }),
    [
      internalAccount,
      kycRestrictedSubMenuStatus,
      shouldDisplayAverageBalanceDocuments,
      shouldDisplayChecksDeposit,
      t,
    ]
  )
}
