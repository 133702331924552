import { useTypeformModal } from 'modules/common/modals/useTypeformModal/useTypeformModal'
import { useCountryConfig } from './useCountryConfig'
import { useUserFeedbackSuccessModal } from './useUserFeedbackSuccessModal'

export const useUserFeedbackTypeformModal = () => {
  const { showModal: showUserFeedbackSuccessModal } =
    useUserFeedbackSuccessModal()

  const { feedbackTypeformId } = useCountryConfig()

  return useTypeformModal({
    formId: feedbackTypeformId,
    onTypeformSubmitted: showUserFeedbackSuccessModal,
    overlayTransitionType: 'transition-none',
  })
}
