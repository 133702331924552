import { useTranslation } from 'modules/common/hooks/useTranslation'
import { useUserFeedbackTypeformModal } from 'modules/common/hooks/useUserFeedbackTypeformModal'

export const UserFeedbackButton = () => {
  const { t } = useTranslation('common-components')

  const { showModal: showUserFeedbackTypeformModal } =
    useUserFeedbackTypeformModal()

  return (
    <button
      className="fixed right-0 top-1/2 flex -translate-y-1/2 rotate-180 rounded-r-lg bg-primary px-1 py-3 text-white"
      onClick={showUserFeedbackTypeformModal}
    >
      <p className="[writing-mode:vertical-lr]">
        {t('userFeedbackButtonLabel')}
      </p>
    </button>
  )
}
