export enum EnumSidebarMenu {
  HOME = 'HOME',
  ACCOUNT = 'ACCOUNT',
  INVOICING = 'INVOICING',
  SERVICES = 'SERVICES',
  SETTINGS = 'SETTINGS',
}

export enum EnumSidebarSubMenu {
  ACCOUNT_HOME = 'ACCOUNT_HOME',
  ACCOUNT_TRANSFER_ORDERS = 'ACCOUNT_TRANSFER_ORDERS',
  ACCOUNT_BENEFICIARIES = 'ACCOUNT_BENEFICIARIES',
  ACCOUNT_CARD = 'ACCOUNT_CARD',
  ACCOUNT_MANDATES = 'ACCOUNT_MANDATES',
  ACCOUNT_CHECK = 'ACCOUNT_CHECK',
  ACCOUNT_AVERAGE_BALANCE_DOCUMENTS = 'ACCOUNT_AVERAGE_BALANCE_DOCUMENTS',
  INVOICING_HOME = 'INVOICING_HOME',
  INVOICING_CUSTOMERS = 'INVOICING_CUSTOMERS',
  INVOICING_SETTINGS = 'INVOICING_SETTINGS',
  SETTINGS_HOME = 'SETTINGS_HOME',
  SETTINGS_INSURANCES = 'SETTINGS_INSURANCES',
  SETTINGS_PROFILE = 'SETTINGS_PROFILE',
  SETTINGS_AUTHENTICATION = 'SETTINGS_AUTHENTICATION',
  SETTINGS_SPONSORSHIP = 'SETTINGS_SPONSORSHIP',
}

export interface SubMenu {
  id: EnumSidebarSubMenu
  link: string
  status: EnumSubMenuStatus
}

export interface Menu {
  id: EnumSidebarMenu
  icon: JSX.Element
  link: string
  subMenus?: SubMenu[]
  title: string
}

export enum EnumSubMenuStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
}
