import { useAnimation } from 'framer-motion'
import { useSidebarContextState } from 'modules/common/contexts/SidebarContext/SidebarContext'
import { useEffect } from 'react'

export const subMenuVariants = {
  closed: {
    x: '-12rem',
    transition: { ease: 'backIn' },
  },
  opened: {
    x: 0,
    transition: { ease: 'backIn' },
  },
}

export const useSubMenusAnimation = () => {
  const controls = useAnimation()
  const { isSidebarOpen } = useSidebarContextState()

  useEffect(() => {
    controls.start(subMenuVariants[isSidebarOpen ? 'opened' : 'closed'])
  }, [isSidebarOpen, controls])

  return controls
}
