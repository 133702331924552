import { useAdditionalServices } from 'modules/services/hooks/main/useAdditionalServices'
import { useMemo } from 'react'
import { IconHouse } from '../../components/_icons/icons'
import { EnumRoutes } from '../../routes'
import { EnumSidebarMenu, Menu } from '../../types/sidebar'
import { useTranslation } from '../useTranslation'
import { useAccountPageSidebarConfig } from './useAccountPageSidebarConfig'
import { useInvoicingPageSidebarConfig } from './useInvoicingPageSidebarConfig'
import { useServicesPageSidebarConfig } from './useServicesPageSidebarConfig'
import { useSettingsPageSidebarConfig } from './useSettingsPageSidebarConfig'

export const useSidebarConfig = () => {
  const { t } = useTranslation('common-components')

  const { additionalServices } = useAdditionalServices()

  const accountPageSidebarConfig = useAccountPageSidebarConfig()
  const invoicingPageSidebarConfig = useInvoicingPageSidebarConfig()
  const servicesPageSidebarConfig =
    useServicesPageSidebarConfig(additionalServices)
  const settingsPageSidebarConfig = useSettingsPageSidebarConfig()

  const menus: Menu[] = useMemo(
    () => [
      {
        id: EnumSidebarMenu.HOME,
        icon: <IconHouse />,
        link: EnumRoutes.HOME,
        title: t(`sidebar.menuLabels.${EnumSidebarMenu.HOME}`),
      },
      accountPageSidebarConfig,
      invoicingPageSidebarConfig,
      ...((additionalServices ?? []).length > 0
        ? [servicesPageSidebarConfig]
        : []),
      settingsPageSidebarConfig,
    ],
    [
      accountPageSidebarConfig,
      additionalServices,
      invoicingPageSidebarConfig,
      servicesPageSidebarConfig,
      settingsPageSidebarConfig,
      t,
    ]
  )

  return { menus }
}
