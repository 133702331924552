import { IconSettings } from 'modules/common/components/_icons/icons'
import { EnumRoutes } from 'modules/common/routes'
import {
  EnumSidebarMenu,
  EnumSidebarSubMenu,
  EnumSubMenuStatus,
} from 'modules/common/types/sidebar'
import { useCorporateSponsorship } from 'modules/corporate/hooks/useCorporateSponsorship'
import { useMemo } from 'react'
import { useFeatureFlipping } from '../useFeatureFlipping'
import { useTranslation } from '../useTranslation'

export const useSettingsPageSidebarConfig = () => {
  const { t } = useTranslation('common-components')

  const { canAccessSettings } = useFeatureFlipping()

  const { isEligibleForSponsorship } = useCorporateSponsorship()

  return useMemo(
    () => ({
      id: EnumSidebarMenu.SETTINGS,
      icon: <IconSettings />,
      link: EnumRoutes.SETTINGS_SUBSCRIPTION_HOME,
      title: t(`sidebar.menuLabels.${EnumSidebarMenu.SETTINGS}`),
      subMenus: [
        {
          id: EnumSidebarSubMenu.SETTINGS_HOME,
          link: EnumRoutes.SETTINGS_SUBSCRIPTION_HOME,
          status: EnumSubMenuStatus.ACTIVE,
        },
        ...(canAccessSettings
          ? [
              {
                id: EnumSidebarSubMenu.SETTINGS_PROFILE,
                link: EnumRoutes.SETTINGS_PROFILE_PERSONAL_INFORMATION,
                status: EnumSubMenuStatus.ACTIVE,
              },
              {
                id: EnumSidebarSubMenu.SETTINGS_INSURANCES,
                link: EnumRoutes.SETTINGS_INSURANCES,
                status: EnumSubMenuStatus.ACTIVE,
              },
              {
                id: EnumSidebarSubMenu.SETTINGS_AUTHENTICATION,
                link: EnumRoutes.SETTINGS_AUTHENTICATION,
                status: EnumSubMenuStatus.ACTIVE,
              },
            ]
          : []),
        ...(isEligibleForSponsorship
          ? [
              {
                id: EnumSidebarSubMenu.SETTINGS_SPONSORSHIP,
                link: EnumRoutes.SETTINGS_SPONSORSHIP,
                status: EnumSubMenuStatus.ACTIVE,
              },
            ]
          : []),
      ],
    }),
    [canAccessSettings, isEligibleForSponsorship, t]
  )
}
