import { mergeClasses } from '@blank/utilities'
import { motion, Variants } from 'framer-motion'
import { SubMenu } from 'modules/common/types/sidebar'
import { useState } from 'react'
import MenuToggle from './MenuToggle'
import SubMenus from './SubMenus'

const extensibleBarVariants: Variants = {
  closed: {
    width: '4rem',
  },
  opened: {
    width: '16rem',
  },
}

interface Props {
  isSidebarOpen: boolean
  selectedSubMenuId: string | undefined
  subMenus: SubMenu[] | undefined
}

const ExtensibleBar = ({
  isSidebarOpen,
  selectedSubMenuId,
  subMenus = [],
}: Props) => {
  const [isFullyOpen, setIsFullyOpen] = useState(isSidebarOpen)

  if (subMenus.length === 0) {
    return null
  }

  return (
    <motion.div
      initial={false}
      animate={isSidebarOpen ? 'opened' : 'closed'}
      variants={extensibleBarVariants}
      onAnimationComplete={() => {
        setIsFullyOpen(isSidebarOpen)
      }}
      transition={{
        ease: 'backIn',
      }}
      className={mergeClasses('hidden flex-col bg-info-90 p-4', {
        flex: selectedSubMenuId,
      })}
    >
      <MenuToggle isFullyOpen={isFullyOpen} />
      <div className="pl-6">
        <SubMenus
          isFullyOpen={isFullyOpen}
          subMenus={subMenus}
          selectedSubMenuId={selectedSubMenuId}
        />
      </div>
    </motion.div>
  )
}

export default ExtensibleBar
